import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {AldersgateContext, AldersgateConnection} from './context/aldersgate';
import { NavBar } from "./components";
import { Home, Dashboard, NewEvent, ViewEvent, NewGroup, ParticipantRegistration, Translate, TranslateDashboard, TranslateEditPage, RegisterUser, LoginPage, ViewGroup, ViewQReports, ViewQReportAdmin, AdminDashboard, UserProfile, EmailConfirm, ResetPassword, ParticipantEventView, PrivacyPolicy, TOS, Resources } from "./routes";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import pink from '@mui/material/colors/pink';
import LocalWrapper from './localization/LocalWrapper';
import localizer from './localization/localizer';
import AuthContext from './context/AuthContext';
import {LoadAuthToken, ClearAuthToken} from './components/auth/LoadAuthToken';
import {AppLocalizationProvider, AppLocalization} from './context/applocalization';

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#3573b9',
    },
    secondary: {
      main: pink[500],
    },
  },
});

const supportedLanguages = localizer.GetSupportedLanguages();

function RedirectWithParams(params) {
    let lang = params.variables.lang;
    let page = null;

    if(!lang || lang=== "")
        lang = localizer.getLanguage();

    if(supportedLanguages.includes(params.variables.lang)) {
        lang = params.variables.lang;
    } else {
        page = params.variables.lang;
        lang = localizer.getLanguage();
    }

    console.log('****' + lang);
    console.log(JSON.stringify(params));

    lang  = "/" + lang;

    if(page !== null) {
    page = "/" + page;
    }

    if(params.variables.p3) {
        return(
            <Redirect to={`${lang}${page}/${params.variables.p1}/${params.variables.p2}/${params.variables.p3}`} />
        );
    }

    if(params.variables.p2) {
        return(
            <Redirect to={`${lang}${page}/${params.variables.p1}/${params.variables.p2}`} />
        );
    }

    if(params.variables.p1) {
        return(
            <Redirect to={`${lang}${page}/${params.variables.p1}`} />
        );
    } else {
        return(
            <Redirect to={`${lang}${page}`} />
        );
    }
}

function App() {

    const [accountState, setAccountState] = useState({loggedIn:false, token:null, level:0, adID:null, expiry:null});
    const [loadValue, setLoadValue] = useState(0);
    const [tokenLoaded, setTokenLoaded] = useState(false);

    useEffect(() => {
        let token = LoadAuthToken();

        if(token) {
            let loggedIn = false;
            if(token.token !== null && token.token !== undefined && token.token !== "")
                loggedIn = true;

            setAccountState({...token});
        }

        setTokenLoaded(true);
    }, [loadValue]);

    var LogoutUser = () => {
        ClearAuthToken();
        setAccountState({loggedIn:false, token:null, level:0, adID:null, expiry:null});
        setLoadValue(loadValue + 1);
    }

    //initialize the Aldersgate API/User state
    const aldersgateInstance = new AldersgateConnection();
    aldersgateInstance.SetLogoutUser(LogoutUser);
    aldersgateInstance.SetUserSession(accountState);

    //initialize the AppLocalization instance
    // const appLocalizationInstance = new AppLocalization();
    // appLocalizationInstance.SetUserLanguage("en");
    
    if(!tokenLoaded) {
        return (
            <div className="App"></div>
        )
    }

    //get all components of the url
    //if the first component is a language, then use it
    let url = window.location.pathname;
    let urlComponents = url.split("/");
    let lang = urlComponents[1];

    //detect mighty networks request, redirect to new MN URL
    if(urlComponents.length > 1 && (urlComponents[1] == "posts" || urlComponents[1] == "spaces" ||  urlComponents[1] == "discovery" ||  urlComponents[1] == "feed")) {

        if(urlComponents.length > 1) {
            let redirectURL = "https://connect.iliteam.org";

            //concat all the components after the first one
            for(let i = 1; i < urlComponents.length; i++) {
                redirectURL += "/" + urlComponents[i];
            }

            window.location.href = redirectURL; 

            return (<div></div>);
        }
    }

    if(supportedLanguages.includes(lang)) {
        console.log("Setting language to " + lang);
        localizer.setLanguage(lang);
    } 

    //
    return (

    <div className="App">
        <Router>
        
        <LocalWrapper />
        <AuthContext.Provider value={{accountState, setAccountState}}>
        <AldersgateContext.Provider value={aldersgateInstance}>
        <ThemeProvider theme={outerTheme}>
        <NavBar />
        <Switch>
            <Route path="/:lang/dashboard/:page/:team" exact component={(props) => <Dashboard {...props} />} /> 
            <Route path="/:lang/dashboard/:page" exact component={(props) => <Dashboard {...props} />} /> 
            <Route path="/:lang/dashboard" exact component={(props) => <Dashboard {...props} />} /> 
            <Route path="/:lang/admin" exact component={() => <AdminDashboard />} />
            <Route path="/:lang/event/new" exact component={() => <NewEvent />} />
            <Route path="/:lang/group/new" exact component={() => <NewGroup />} />
            <Route path="/:lang/event/view/:id" exact component={(props) => <ViewEvent {...props} />} />
            <Route path="/:lang/event/vista/:id" exact component={(props) => <ParticipantEventView {...props} />} />
            <Route path="/:lang/group/view/:id" exact component={(props) => <ViewGroup {...props} />} />
            <Route path="/:lang/reports/view/:id" exact component={(props) => <ViewQReports {...props} />} />
            <Route path="/:lang/reports/admin/:id" exact component={(props) => <ViewQReportAdmin {...props} />} />
            <Route path="/:lang/resources/:id" exact component={(props) => <Resources {...props} />} />
            <Route path="/:lang/resources" exact component={(props) => <Resources {...props} />} />
            <Route path="/:lang/reg/:id" exact component={(props) => <ParticipantRegistration {...props} />} />
            <Route path="/:lang/translate/page/:id" exact component={(props) => <Translate {...props} />} />
            <Route path="/:lang/translate/admin/dashboard" exact component={(props) => <TranslateDashboard {...props}/>} />
            <Route path="/:lang/translate/admin/page/:id" exact component={(props) => <TranslateEditPage {...props}/>} />
            <Route path="/:lang/user/register" exact component={() => <RegisterUser />} />
            <Route path="/:lang/user/login" exact component={() => <LoginPage />} />
            <Route path="/:lang/user/profile" exact component={() => <UserProfile />} />
            <Route path="/:lang/emailconfirm/:confirmCode" exact component={(props) => <EmailConfirm {...props}/>} />
            <Route path="/:lang/resetpassword/:confirmCode" exact component={(props) => <ResetPassword {...props}/>} />
            <Route path="/:lang/privacy" exact component={() => <PrivacyPolicy />} />
            <Route path="/:lang/tos" exact component={() => <TOS />} />

            <Route path="/:lang/:p1/:p2" render={props =>
            supportedLanguages.includes(props.match.params.lang) ? (
                <Home {...props} />
            ) : (
                <RedirectWithParams variables={props.match.params} />
            )
            } />

            <Route path="/:lang/:p1" render={props =>
            supportedLanguages.includes(props.match.params.lang) ? (
                <Home {...props} />
            ) : (
                <RedirectWithParams variables={props.match.params} />
            )
            } />

            <Route path="/:lang" render={props =>
            supportedLanguages.includes(props.match.params.lang) ? (
                <Home {...props} />
            ) : (
                <RedirectWithParams variables={props.match.params} />
            )
            } />

        </Switch>
        </ThemeProvider>
        </AldersgateContext.Provider>
        </AuthContext.Provider>
        </Router>
    </div>

    );
}

export default App;
          //<Route path="/:lang/event/new" exact component={() => <NewEvent />} />