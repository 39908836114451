import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import ADPeopleList from './ADPeopleList/ADPeopleList';
import PeopleFilter from './PeopleFilter';
import UserViewModal from './UserViewModal';
import Grid from '@mui/material/Grid';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import EventViewModal from './EventViewModal';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

export default function APeopleView(props) {
    
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const [filterData, setFilterData] = useState({ name: '', lastLogin: 0 });
    const [eventData, setEventData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [actionModalOpen, setActionModalOpen] = useState(false);

    const aldersgateContext = useContext(AldersgateContext);

    // const theme = useTheme();
    // const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    function ProcessPeople(events) {
        let eventList = [];

        let index = 0;
        events.forEach(event => {
            let location = 'Unknown';
            if(event !== undefined && event !== null) {
                let loc = [];

                if(event.city !== undefined && event.city !== null && event.city !== '')   
                    loc.push(event.city);
                if(event.state !== undefined && event.state !== null && event.state !== '')
                    loc.push(event.state);
                if(event.country !== undefined && event.country !== null && event.country !== '')
                    loc.push(event?.country.toUpperCase());

                if(loc.length > 0) {
                    location = loc.join(', ');
                } else {
                    location = 'Unknown';
                }
            }

            let completedParticipants = event.participants ? event.participants.length : 0;


            eventList.push({
                ...event, id: index++, location: location, completedParticipants: completedParticipants
            });
        });

        return eventList;
    }

    function HandleFilterOnChange(parameter, filterData) {
        setFilterData(prevState => {
            return {...prevState, [parameter]: filterData};
        });
    }

    function SearchWithFilter() {
        let query = '';

        if(filterData.name !== '')
            query += '&name=' + filterData.name;

        if(filterData.lastLogin !== 0)
            query += '&lastLogin=' + filterData.lastLogin.toString();

        query += '&queryLimit=100';

        console.log(query);

        aldersgateContext.GetFromAPI('admin/getlist/peoplequery/s', query, 1).then((success, err) => {
            if(success) {
                console.log("Success: " + JSON.stringify(success, null, 2));
                setEventData(ProcessPeople(success));
            }
    
            if(err) {
                console.log("Error: " + err);
                setEventData([]);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            setEventData([]);
        });
            
    }

    function viewUserDetails(event){
        let eventID = event;
        console.log('viewEventDetails: ' + eventID);

        //find the event in the reportData
        let eventItem = eventData.find((item) => item.id === eventID);

        console.log('eventItem: ' + JSON.stringify(eventItem, null, 4));

        setSelectedUser(eventItem);

        setActionModalOpen(true);

    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PeopleFilter filterData={filterData} search={SearchWithFilter} setFilterData={setFilterData}/>
            </Grid>
            <Grid item xs={12}>
                <ADPeopleList eventData={eventData} onRowClick={viewUserDetails}/>
                <Modal
                open={actionModalOpen}
                onClose={()=>{setActionModalOpen(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <UserViewModal user={selectedUser} />
                    </Box>
                </Modal>
            </Grid>
        </Grid>
    );
}