import React, {useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { AldersgateContext } from '../context/aldersgate';
import UserLogin from "../components/user/UserLogin";
//import queryString from 'query-string';
import SophConstants from '../constants/SophConstants';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TranslationPage from "../components/translation/TranslationPage";
import Button from '@mui/material/Button';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function Translate(props) {
    const pageIdentifier = props.match.params.id;


    const searchParams = new URLSearchParams(props.location.search);
    const lang = searchParams.get('lang');
    //console.log("lang: " + lang);

    //let params = queryString.parse(props.location.search)
    let language = "";

    if(lang) {
        language = lang;
    }

    
    console.log(JSON.stringify(searchParams));

    const [value, setValue] = useState(0);
    const [originalData, setOriginalData] = useState({});
    const [translationData, setTranslationData] = useState({});
    const [hasOriginal, sethasOriginal] = useState(false);

    const [formDisabled, setFormDisabled] = useState(true);

    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const authContext = React.useContext(AuthContext);
    const [aState, setAState] = React.useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});
    
    const aldersgateContext = useContext(AldersgateContext);

    React.useEffect(() => {
      setAState(prev => {
          return({...authContext.accountState});
      });
    }, [authContext]);

    useEffect(() => {

        if(!hasOriginal && pageIdentifier && language != "") {
            aldersgateContext.GetFromAPI('translation/get/en/' + pageIdentifier).then((success, err) => {

                if(success) {
                    CreateTranslationData(success).then((resolve, reject) => {
                        sethasOriginal(true);
                        GetTranslationFromAPI();
                    }).catch((err) => {
                        console.log("Error: " + err);
                    });
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });

            //fetch(SophConstants.API_ADDR + '/api/translation/get/en/' + pageIdentifier + '?api-key=foo', {
        }

    }, [value]);

    const GetTranslationFromAPI = () => {
        aldersgateContext.GetFromAPI('translation/get/' + language + '/' + pageIdentifier).then((success, err) => {

            if(success) {
                SetLanguageTranslation(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/translation/get/' + language + '/' + pageIdentifier + '?api-key=foo', {
    }

    //check for token, block if not logged in
    if(aState.loggedIn === false) {
        return <UserLogin />
    }

    // callback function for text field value change
    const onTranslationChange = input => e => {
        setFormDisabled(false);

        setTranslationData(prevState => {
            // Object.assign would also work
            let translationObject = {...prevState};
            translationObject.translations[input].translation = e.target.value;
            translationObject.translations[input].changed = true;
            return {...prevState, ...translationObject};
        });
        
    };

    const onTranslationSave = () => {
        setFormDisabled(true);
        setSnackbar({ children: 'Translaton successfully saved!', severity: 'success' });
        
        let translationObject = {...translationData};
        let changedArray = [];
        for (var key in translationObject.translations) {
            if(translationObject.translations[key].changed) {
                translationObject.translations[key].containerID = translationObject.containerID;
                changedArray.push(translationObject.translations[key]);
            }
            
        }

        if(changedArray.length > 0) {

            let data = {
                languageID: translationObject.languageID,
                pageIdentifier: translationObject.pageIdentifier,
                translationID: translationObject.translationID,
                translations: changedArray
                
            };

            aldersgateContext.PostToAPI('translation/addtranslations/' + language + '/' + pageIdentifier, data, null, 1).then((success, err) => {
                if(success) {
                    
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });

            //fetch(SophConstants.API_ADDR + '/api/translation/addtranslations/' + language + '/' + pageIdentifier + '?api-key=foo', {
        }
    };

    var CreateTranslationData = (data) => {
        console.log("creating translation data");
        return new Promise ((resolve, reject) => {
            let translationObject = {...data};
            translationObject.onTranslationChange = onTranslationChange;
            translationObject.originalID = data.adID;

            translationObject.translations = {};
            
            data.translations.forEach(function(value) {
            let translationInput = {...value};
                translationInput.original = translationInput.translation;
                translationInput.translation = "";
                translationInput.changed = false;
                
                translationObject.translations[translationInput.adID] = translationInput;
            });

        
            setTranslationData(translationObject);
            
            resolve(true);
        });
        //{JSON.stringify(translationData)}
    }

    var SetLanguageTranslation = (data) => {
        console.log(JSON.stringify(data));
        setTranslationData(prevState => {
            let translationObject = {...prevState};
            
            translationObject.containerID = data.adID;
            translationObject.languageID = data.languageID;

            data.translations.forEach(function(value) {
                if(translationObject.translations[value.adID]) {
                    let translationInput = {...translationObject.translations[value.adID]};
                    translationInput.translation = value.translation;
                    translationInput.containerID = data.adID;
                    translationObject.translations[translationInput.adID] = translationInput;
                }
            });

            return {...prevState, ...translationObject};
        });

    }

  return (
    <Box sx={{ flexGrow: 1 }}>
            <Box m={1.5} bgcolor="background.paper" >
                <Grid container>
                    <Grid item xs={12}>
                    <Box pb={4}>
                        <Typography component="h2" variant="h5" color="textSecondary" align="center">
                        Language: {language}
                        </Typography>
                        <Typography component="h2" variant="h6" color="textSecondary" align="center">
                        https://Online.ILITeam.org/{translationData.pageIdentifier}
                        </Typography>
                    </Box>
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                        <Grid container>
                            <Box sx={{width:'100%'}}>
                                <TranslationPage translationData={translationData}/>
                                {!!snackbar && (
                                    <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
                                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                                    </Snackbar>
                                )}
                            </Box>
                        </Grid>

                    </Grid>
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} style={{paddingTop:40}}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={formDisabled}
                            onClick={onTranslationSave}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
  );
}
