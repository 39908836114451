import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {CardActionArea, CardActions } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import FeedIcon from '@mui/icons-material/Feed';
import VideocamIcon from '@mui/icons-material/Videocam';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FilterIcon from '@mui/icons-material/Filter';
import DescriptionIcon from '@mui/icons-material/Description';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

function GetTypeIcon(postType) {
    switch (postType) {
        case "video":
            return <OndemandVideoIcon />;
        case "announcement":
            return <ChatBubbleIcon />;
        case "article":
            return <FeedIcon />;
        case "callinvite":
            return <VideocamIcon />;
        case "video":
            return <VideocamIcon />;
        case "podcast":
            return <PodcastsIcon />;
        case "link":
            return <ExitToAppIcon />;
        case "pdf":
            return <MenuBookIcon sx={{ color:'blue' }} />;
        case "ppt":
            return <FilterIcon sx={{ color:'red' }} />;
        case "docx":
            return <DescriptionIcon sx={{ color:'lightblue' }} />;
        case "doc":
            return <DescriptionIcon sx={{ color:'lightblue' }} />;
      default:
        break;
    }
  }

export default function ADPostListCard(props) {
    const { rowItemData } = props;
    const [value, setValue] = useState(0);
    const [buttonEnabled, setButtonEnabled ] = useState(true);
    const [submitStatus, setSubmitting] = useState({status:0});

    const history = useHistory();

    useEffect(() => {
        setButtonEnabled(rowItemData.createSFButtonEnabled);
    }, [rowItemData]);

    useEffect(() => {

        if(rowItemData.salesforceID !== undefined && rowItemData.salesforceID !== null) {
            setSubmitting({status:2});
        } else {
            if(submitStatus.status !== 1)
                setSubmitting({status:0});
        }
        
    }, [rowItemData]);


    const OpenPostModal = (postID) => {
        
    }

    function SubmitButtonIcon(props) {
        
        switch (props.submitStatus) {
            case 0:
                return null; 
            case 1:
                return (<CircularProgress size={20}/>);
            case 2:
                return (<CheckIcon />);
            default:
                return null;
        }
    }

    const options = {month: 'numeric', day: 'numeric', year: 'numeric'}
    let bgColor = '#FFFFFF';
    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    return (
        <Card square={true} sx={{ border: 0, boxShadow: 0, backgroundColor:bgColor, ':hover': {backgroundColor:'#E0E0E0'}}}>
            <CardActionArea onClick={() => rowItemData.onRowClick(rowItemData.id)}> 
                <CardContent>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'flex-start', gap:'10px'}}>
                        <Box pt={1}>
                            {GetTypeIcon(rowItemData.type)}
                        </Box>
                        <Box sx={{width:'100%'}}>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                        <Box>
                                            <Typography color="text.primary" sx={{ fontSize: 16, textAlign:'left'}}>
                                                {rowItemData?.title}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'left'}}>
                                            Posted {new Date(rowItemData.publishDate).toLocaleDateString(undefined, options)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{height:"20px"}}>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'left', textOverflow:'ellipsis', whiteSpace: 'wrap', overflow: 'hidden'}}>
                                                {rowItemData.preview}
                                            </Typography>
                                        </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}