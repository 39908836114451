import React, { useContext, useState } from 'react';
import SophConstants from "../constants/SophConstants";
// import AlderLocalizer from '../localization/AlderLocalizer';

export const AppLocalizationContext = React.createContext();

export class AppLocalization {
    static instance = null;

    //cosntructor
    constructor() {
      if(AppLocalization.instance !== null) {
          return AppLocalization.instance;
      }

      this.getFromAPI = null;
      this.supportedLanguages = ["en", "es"];
      this.userLanguage = "en";
      //this.alderLocalizer = new AlderLocalizer();
    }

    static getInstance() {
      if(AppLocalization.instance === null) {
          AppLocalization.instance = new AppLocalization();
      }
  }

    SetGetFromAPI(aContextGet) {
        AppLocalization.getFromAPI = aContextGet;
    }

    SetLocalizationData(localizationData, setLocalizationData) {
        console.log("*Setting localization data container and update function");
        //this.alderLocalizer.SetLocalizationData(localizationData, setLocalizationData);
    }

    SetUserLanguage(aLanguage) {
      console.log("**Setting language to: " + aLanguage);
      //this.alderLocalizer.userLanguage = aLanguage;
      //this.alderLocalizer.setLanguage(aLanguage);
    }

    GetUserLanguage() {
      //return this.alderLocalizer.language;
    }

    t(page, key, fallback, params) {
        //return this.alderLocalizer.t(page, key, fallback, params);
    }

}

// The provider, which holds the current language code
export function AppLocalizationProvider({ children }) {
  const [localizationData, setLocalizationData] = useState([]);

  const appLocalizationInstance =  new AppLocalization();

  appLocalizationInstance.SetLocalizationData(localizationData, setLocalizationData);

  appLocalizationInstance.SetUserLanguage("en");
  return (
    <AppLocalizationContext.Provider value={appLocalizationInstance}>
      {children}
    </AppLocalizationContext.Provider>
  );
}

// A hook to make it easier to use the context
export function UseAppLocalization() {
  return useContext(AppLocalizationContext);
}

