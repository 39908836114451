import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import FeedIcon from '@mui/icons-material/Feed';
import VideocamIcon from '@mui/icons-material/Videocam';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FilterIcon from '@mui/icons-material/Filter';
import DescriptionIcon from '@mui/icons-material/Description';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

function GetTypeIcon(postType) {
    switch (postType) {
        case "video":
            return <OndemandVideoIcon />;
        case "announcement":
            return <ChatBubbleIcon />;
        case "article":
            return <FeedIcon />;
        case "callinvite":
            return <VideocamIcon />;
        case "video":
            return <VideocamIcon />;
        case "podcast":
            return <PodcastsIcon />;
        case "link":
            return <ExitToAppIcon sx={{ color:'grey' }} />;
        case "pdf":
            return <MenuBookIcon sx={{ color:'blue' }} />;
        case "ppt":
            return <FilterIcon sx={{ color:'red' }} />;
        case "docx":
            return <DescriptionIcon sx={{ color:'lightblue' }} />;
        case "doc":
            return <DescriptionIcon sx={{ color:'lightblue' }} />;
      default:
        break;
    }
  }

export default function AContentListRow(props) {
    const { rowItemData } = props;
    const [buttonEnabled, setButtonEnabled ] = useState(true);
    const [submitStatus, setSubmitting] = useState({status:0});

    const history = useHistory();

    useEffect(() => {
        setButtonEnabled(rowItemData.createSFButtonEnabled);
    }, [rowItemData]);

    useEffect(() => {

        if(rowItemData.salesforceID !== undefined && rowItemData.salesforceID !== null) {
            setSubmitting({status:2});
        } else {
            if(submitStatus.status !== 1)
                setSubmitting({status:0});
        }
        
    }, [rowItemData]);

    const options = {month: 'numeric', day: 'numeric', year: 'numeric'};
    let bgColor = '#FFFFFF';
    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    let fontSizeTitle = 14;
    let fontSizeSubTitle = 12;

    const viewEventDetails = (event) => {
        let eventID = event;
        history.push("/event/view/" + eventID);
    }

    const DisplayFileSize = (size) => {
        let fsize = size;
        let fsizeStr = 'kb';
        let decimal = 0;

        if(parseInt(fsize) > 1000) {
            fsize = fsize / 1000;
            fsizeStr = 'mb';
            decimal = 1;
        }
        
        return (
            <Box>
                {fsize.toFixed(decimal)} {fsizeStr}
            </Box>
        )
    }

            
    return (
        <Box onClick={() => rowItemData.onRowClick(rowItemData.id)}>
            <Grid container p={1} sx={{backgroundColor:bgColor,':hover': {backgroundColor:'#E0E0E0'}}} >
                
                <Grid item xs={1}  sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', alignItems:'center', gap:'10px'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {GetTypeIcon(rowItemData.type)}
                    </Box>
                </Grid>

                <Grid item xs={7} sm={8}>
                    <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                        <Box>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                <Box sx={{  fontSize: fontSizeTitle, textAlign:'left' }}>
                                    {rowItemData?.title}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={2}  sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                    {new Date(rowItemData.publishDate).toLocaleDateString(undefined, options)}
                    </Box>
                </Grid>


                <Grid item xs={2} sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {DisplayFileSize(rowItemData?.fsize)}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}